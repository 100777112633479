.customize-side-input {
  flex: 0.2 !important;
  text-align: end;
  color: lightgray;
}

// .customize-side-input {
//   flex: 0.9 !important;
// }
.customize-first-border {
  border-right: none;
}

.customize-second-border {
  border-left: none;
}

.customize-img-container {
  position: relative;
  padding: 8px 25px 8px 0px;

  img {
    width: 100%;
  }
}

.customize-btn-container{
  position: absolute;
  top:0;
  &:hover {
    color:red;
    background-color: lightgray;
    border-radius: 50%;
  }
}
.customize-f-end {
  display: flex;
  justify-content: flex-end;
}
.customize-divider {
  height: 1px;
  width: 100%;
  background-color: $grey8;
  margin: 10px 0px;
}

.customize-table-header {
  display: flex;
  justify-content: space-between;
}

.customize-order-table-header {
  display: flex;
}

.customize-reminder-text {
  font-size: 12px;
  color: $light-red;
  font-weight: 600;
}

.customize-filter-tab-header{
  margin: 0px 0px 20px 0px;
  font-size: 13px !important;
}

.customize-order-table-image {
  display: flex;
  white-space: normal !important;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.customize-a {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  &:hover {
    color: #007bff;
    text-decoration: underline;
  }
}
.SSMID-padding{
  padding:0.48rem, 0rem;
}
.customize-link {
  padding: 0.48rem 0rem;
   margin: 0;
  display: flex;
  align-items: center;
}
.customize-picker-icon {
  color: $primary-color;
  height: 15px;
  margin-right: 5px;
  margin-bottom: -1px;
}
.customize-picker-m {
  border-radius: 0.25rem;
  font-size: 14px;
  width: 230px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  // text-align: center;
  align-items: center;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.customize-commission-red {
  color: $color-red !important;
}

.customize-commission-green {
  color: $light-green !important;
}

.customize-header-bottom-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: -10px;
}

.customize-commission-target-text {
  display: flex;
  font-size: 13px;
  font-weight: 700;
}

.table-header-tooltip-wrapper {
  display: flex;
  align-items: center;
}

// Page Settings
.form-gap {
  margin-top: 40px;
}

.tooltip-icon {
  height: 15px;
  margin-bottom: -2px;
  color: $light-red;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .customize-dropdown-menu {
    width: unset;
  }
}

@media only screen and (max-width: 575px) {
  .customize-dropdown-menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 5px 15px 5px 0px;
  }
  .customize-a {
    padding-left: 15px;
  }
  .SSMID-padding{
    padding:0.48rem, 15px;
    // padding-left: 15px;
  }
  .empty-label{
    padding-left: 15px;
  }
}

.ps__rail-y {
  height: 0px !important;
}

.ps__thumb-y {
  height: 0px !important;
}

h5 {
  text-transform: none !important;
}
